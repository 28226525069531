<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>用户编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">
                    <el-form-item label="昵称" prop="F_VR_NIC" :rules="[{required:true,message:'昵称不能为空',trigger: 'blur' }]"><el-input placeholder="请输入昵称" v-model="info.F_VR_NIC"></el-input></el-form-item>
                    <el-form-item label="手机号" prop="phone" :rules="[{required:true,message:'手机号不能为空',trigger: 'blur' }]"><el-input type="number" maxLength="11"  placeholder="请输入手机号" v-model="info.phone" ></el-input></el-form-item>
                    <el-form-item v-if="edit_id==0" label="密码" prop="password" :rules="[{required:true,message:'密码不能为空',trigger: 'blur' }]"><el-input placeholder="请输入密码" v-model="info.password"></el-input></el-form-item>
                    <el-form-item v-else label="密码" prop="password" ><el-input placeholder="不修改则不填" v-model="info.password"></el-input></el-form-item>
                    <el-form-item label="头像" prop="thumb"><el-upload class="avatar-uploader" :action="$api.advUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccess" >
                        <img v-if="info.F_VR_TOUX" :src="info.F_VR_TOUX" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>
                    <el-form-item label="性别" prop="F_TI_XINGB">
                        <el-radio-group v-model="info.F_TI_XINGB">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <el-button @click="resetForm('info')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          edit_id:0,
          info:{},
          roles_list:[],
          roles:[],
          upload_headers:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        resetForm:function(e){
            this.$refs[e].resetFields();
        },
        submitForm:function(e){
            let _this = this;

            // 验证表单
            this.$refs[e].validate(function(res){
                if(res){
                    //  判断是Add 或者 Edit
                    let url = _this.$api.addStoreList;
                    if(_this.edit_id>0){
                        url = _this.$api.editStoreList+_this.edit_id;
                    }
                    // Http 请求
                    _this.$post(url,_this.info).then(function(res){
                        if(res.code == 200){
                            _this.$message.success("编辑成功");
                            _this.$router.go(-1);
                        }else{
                            _this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_users_info:function(){
            let _this = this;
            this.$get(this.$api.editStoreList+this.edit_id).then(function(res){
                _this.info = res.data['info'];
                _this.info.password = '';
            })
        },
        handleAvatarSuccess(res) {
            this.info.F_VR_TOUX = res.data;
            this.$forceUpdate();
        },
    },
    created() {
        this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到
        // 判断是否是编辑
        if(!this.$isEmpty(this.$route.params.id)){
            this.edit_id = this.$route.params.id;
        }
        if(this.edit_id>0){
            this.get_users_info();
        }
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>